<template lang="pug">
.index-page(:class="{ 'padding-page': state.isOpenBtnVisible }")
  .code-box
    img.code-img(:src="pageData.codeImg")
    .save-btn(v-if="state.isYD", @click="downloadImg") 保存图片
    .tips-box
      .tips-title 打开微信扫一扫联系老师
      .tips-content
        p 请您添加客服后, 并根据客服指引领取对应课程
        p （扫码后统一在工作日 10:00-19:00 进行反馈）
    .code-line
      .code-label 领取码
      .code {{pageData.code}}
      .code-btn(@click="copy") 复制
  .desc-box
    img.desc-img(:src="pageData.descImg")
  .open-btn-box(v-if="state.isOpenBtnVisible")
    .open-btn(@click="openDeepLink") 有道智慧学习 App 内打开
  .wx-cover(v-show="state.isCoverVisible")
    .wx-pointer
</template>

<script>
import { reactive } from 'vue';
import compareVersions from 'compare-versions';
import utils from '../utils';
import UpdateDialog from '../components/UpdateDialog.vue';

export default {
  name: 'Index',
  components: {
    UpdateDialog,
  },
  setup() {
    const {
      ios,
      iPhone,
      iPad,
      isIOS9,
      isWeibo,
    } = utils.browser.versions;

    const { isWx, isAndroid } = utils;

    const dictAppleStore = 'https://apps.apple.com/cn/app/%E6%9C%89%E9%81%93%E6%99%BA%E6%85%A7%E5%AD%A6%E4%B9%A0/id1561716363';
    const dictAndroidStore = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.youdao.wisdom';
    const baseUrl = 'ydwisdom://youdao.com';
    const universalBaseUrl = 'https://ul.youdao.com/wisdom';

    const linkName = 'web';
    const cbLink = `https://${window.location.host}${window.location.pathname}#/claim?code=${utils.getParameter('code')}`
    const fullLink = `${baseUrl}/${linkName}?url=${encodeURIComponent(cbLink)}`;

    const { isYD } = window.ydk
    const type = utils.getParameter('type')

    const state = reactive({
      isLogin: true,
      // isOpenBtnVisible: !isYD,
      isOpenBtnVisible: true,
      isCoverVisible: false,
      isYD,
      type,
    })

    const pageData = reactive({
      codeImg: window.detailData.codeImg,
      code: utils.getParameter('code'),
      descImg: window.detailData.shortTerm,
    });

    if (utils.getParameter('type') === 'longTerm') {
      pageData.descImg = window.detailData.longTerm
    }

    // 延迟跳转
    const delayJump = (link) => {
      console.log('delayJump', link)
      // window.location.href = link;

      // 所有跳转加延迟，否则 android 词典内 webview 调 deeplink 被拦截后原页面渲染不完整
      setTimeout(() => {
        window.location.href = link;
      }, isAndroid ? 1000 : 0);
    };
    // 跳转应用商店
    const download = () => {
      if (isWx || isWeibo) {
        state.isCoverVisible = true;
      }
      if (ios || iPhone || iPad) {
        delayJump(dictAppleStore);
      } else if (isAndroid) {
        delayJump(dictAndroidStore);
      }
    };

    let loadTimer = null
    const visibilitychange = () => {
      if (document.hidden || document.webkitHidden) {
        clearTimeout(loadTimer)
      }
    }
    document.addEventListener('visibilitychange', visibilitychange, false)
    document.addEventListener('webkitvisibilitychange', visibilitychange, false)
    window.addEventListener('pagehide', () => {
      clearTimeout(loadTimer)
    }, false)

    const jump = (url) => {
      loadTimer = setTimeout(() => {
        if (document.hidden || document.webkitHidden) {
          return
        }
        download()
      }, 3000)
      delayJump(url)
    }

    const openDeepLink = () => {
      if (ios) {
        const universalLink = `${universalBaseUrl}?deeplink=${encodeURIComponent(fullLink)}&redirect=${encodeURIComponent(dictAppleStore)}`;
        if (!isIOS9) { // iOS 系统小于9
          if (isWx || isWeibo) {
            state.isCoverVisible = true;
          } else {
            jump(fullLink);
          }
          return;
        }
        if (isWeibo) {
          state.isCoverVisible = true;
        } else if (isWx) {
          jump(universalLink);
        } else {
          jump(fullLink);
        }
        return;
      }
      // Android 等环境中的浏览器、微信、微博环境
      if (isWx || isWeibo) {
        state.isCoverVisible = true;
      } else {
        jump(fullLink);
      }
    };

    // if (!isWx && !state.isYD) {
    //   openDeepLink()
    // }

    const downloadImg = () => {
      window.ydk.downloadImageToAlbum({
        serverId: pageData.codeImg,
      }, () => {
        window.ydk.toast({ msg: '保存成功' })
      }, () => {
        const { version } = window.ydk.ni
        const isLower = compareVersions.compare(version, '1.7.4', '<')
        if (isLower) {
          window.ydk.toast({ msg: '请升级APP到最新版本' })
        } else {
          window.ydk.toast({ msg: '保存失败' })
        }
      })
    }

    const login = () => {
      window.ydk.login({
        defer: 3000,
        success: (res) => {
          state.isLogin = res.isLogin
        },
        fail: () => {
          window.ydk.toast({ msg: '请升级APP到最新版本' })
        },
      })
    }

    if (state.isYD) {
      state.isLogin = false
      window.ydk.isLogin({
        success: (res) => {
          console.log('isLogin', res.isLogin)
          if (!res.isLogin) {
            login()
          } else {
            state.isLogin = true
          }
        },
      })
    }

    const copy = () => {
      utils.copy(pageData.code)
        .then(() => {
          window.ydk.toast({ msg: '已复制' })
        })
        .catch(() => {
          window.ydk.toast({ msg: '复制失败' })
        })
    }

    return {
      state,
      pageData,
      openDeepLink,
      downloadImg,
      login,
      copy,
    };
  },
};
</script>

<style lang="stylus">
.wx-cover
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  background rgba(4, 8, 18, 0.6)
.wx-pointer
  width 2.35rem
  height 1.3rem
  position fixed
  top 0
  right 0
  background url("https://oimagec7.ydstatic.com/image?id=1836528283477984378&product=xue") 50% 50% no-repeat
  background-size contain
.index-page
  padding 0.2rem
  text-align center
  color #3a3a3a
  max-width 3.9rem
  margin 0 auto
.padding-page
  padding-bottom 1.44rem
.code-box
  background #F5F5F5
  border-radius 0.16rem
  padding 0.2rem
img
  display block
.code-img
  width 1.4rem
  height 1.4rem
  margin 0 auto
.save-btn
  background linear-gradient(78.93deg, #FF333A 8.18%, #FF6544 91.82%);
  box-shadow 0 0.1rem 0.25rem rgba(255, 51, 58, 0.12);
  border-radius 1rem
  color #fff
  font-size 0.12rem
  font-weight 500
  height 0.38rem
  line-height 0.38rem
  width 0.88rem
  margin 0.2rem auto 0
.tips-title
  font-size 0.16rem
  line-height 0.24rem
  font-weight 600
  margin 0.2rem auto 0.1rem
.tips-content
  font-size 0.14rem
  line-height 0.2rem
  color #bfbfbf
  margin-bottom 0.2rem
.code-line
  background #F0F0F0
  border-radius 0.08rem
  display flex
  font-size 0.14rem
  line-height 0.2rem
  padding 0.1rem
  align-items center
.code-label
  color #bfbfbf
  margin-right 0.12rem
.code
  flex 1
  text-align left
.code-btn
  background #f5f5f5
  border-radius 0.08rem
  padding 0.06rem 0.18rem
  font-size 0.12rem
  font-weight 500
  line-height 0.18rem
  color #000
.desc-box
  margin-top 0.3rem
  border-radius 0.16rem
  overflow hidden
.open-btn-box
  position fixed
  bottom 0
  left 0
  width 100%
  box-sizing border-box
  background #fff
  padding 0.2rem 0.2rem 0.54rem
.open-btn
  background linear-gradient(78.93deg, #FF333A 8.18%, #FF6544 91.82%);
  box-shadow 0px 0.1rem 0.25rem rgba(67, 146, 255, 0.12);
  border-radius 1rem
  color #fff
  font-weight 500
  font-size 0.16rem
  line-height 0.5rem
  height 0.5rem
  margin 0 auto
  max-width 3.9rem
</style>
