<template lang="pug">
.dialog-cover
  .dialog_box
    .content_box
      .state_icon.state_icon-fail
      .content_desc 您的 App 版本过低，请升级至最新版本使用{{pName}}。
    .footer_box
      a.ok_btn(@click="okHandle", href="javascript:;") 确定
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UpdateDialog',
  props: {
    pName: String,
  },
  setup(props, context) {
    const okHandle = () => {
      context.emit('ok');
    };
    return {
      okHandle,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus">
  .dialog-cover
    position fixed
    z-index 3
    left 0
    top 0
    width 100%
    height 100%
    background rgba(9, 11, 15, 0.8)
    .dialog_box
      box-sizing border-box
      width 6.3rem
      padding 0.32rem 0.8rem 0.6rem 0.8rem
      background #FFFFFF
      border-radius 0.3rem
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
      .content_box
        margin-bottom 0.6rem
        .state_icon
          width 3.6rem
          height 3.6rem
          margin 0 auto 0.24rem auto
          background url("../assets/dialog_icon.png") 50% 50% no-repeat
          background-size contain
        .content_desc
          color #666666
          font-size 0.32rem
          line-height 1.375
          text-align center
      .footer_box
        .ok_btn
          display block
          margin 0 auto
          background linear-gradient(78.93deg, #FF333A 8.18%, #FF6544 91.82%)
          box-shadow 0px 10px 25px rgba(255, 51, 58, 0.2)
          border-radius 0.48rem
          font-size 0.3rem
          width 2.3rem
          line-height 3.2
          text-align center
          color #ffffff
</style>
